import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/features/ekologiškos pakavimo granulės dūštantiems daiktams.jpg";
import img2 from "../../assets/images/features/ekologiškos pakavimo granulės iš krakmolo.jpg";
import FeatureBox from "../common/feature-box";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle title1="Produkta" title2="īpašības" />
            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img1}
                    alt="Drošs un uzticams iepakošanas pildmateriāls."
                    title="Drošs un uzticams iepakošanas pildmateriāls."
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Drošs un uzticams iepakošanas pildmateriāls."
                  desc="Iepakošanas granulām ir mīksta virsma, tās nebirst un aizsargā trauslus priekšmetus. Saglabā formu pat tad, ja tiek iepakoti smagi priekšmeti. Šīm granulām nav statiskā lādiņa, tāpēc tās ir drošas elektroniskām ierīcēm."
                />
              </Col>
            </Row>
            <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="100% noārdāms augu izcelsmes produkts"
                  desc="Bioloģiski noārdāmās iepakošanas granulas ir 100 % augu izcelsmes, tāpēc tās ir drošas videi un cilvēkiem. Granulas var izmantot atkārtoti. Tās ir izgatavotas no bioloģiski noārdāmas cietes, pilnībā šķīst ūdenī, un tās var kompostēt uz zemes, neatstājot toksiskas vielas vai piesārņotājus augsnē vai ūdenī."
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img2}
                    alt="100% noārdāms augu izcelsmes produkts"
                    title="100% noārdāms augu izcelsmes produkts"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
