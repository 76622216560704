import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from '../../components/common/ModalSection';

//Import Image
// import img1 from "../../assets/images/bg-home.jpg";

class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.callModal.bind(this)
    }

    callModal = () => {
        this.refs.child.openModal();
    }

    render() {
        return (
            <React.Fragment>
                <section className="bg-home" id="home">
                    <div className="bg-overlay"></div>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <Container>
                                <Row className="row justify-content-center">
                                    <Col lg="9" className="text-center">
                                        <h1 className="home-title text-white">Loose Fill bioloģiski noārdāmās iepakošanas granulas</h1>
                                        <p className="pt-4 home-sub-title text-white mx-auto">Loose Fill piedāvā mūsdienīgu iepakošanas risinājumu, kas ir 100% augu izcelsmes, drošs, videi un veselībai draudzīgs.</p>
                                        <div className="watch-video pt-4">
                                            {/*https://pictogrammers.github.io/@mdi/font/2.0.46/*/}
                                            <a href="tel:+370 698 20093" className="video-play-icon text-white"><i className="mdi mdi-phone play-icon-circle play play-iconbar"></i> <span>+370 698 20093</span></a>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;
