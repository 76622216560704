import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
    state = {
        services : [
            { title : "Digital Design", icon : "mbri-code", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Unlimited Colors", icon : "mbri-features", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Strategy Solutions", icon : "mbri-growing-chart", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
        ],
        services1 : [
            { title : "Awesome Support", icon : "mbri-photos", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Truly Multipurpose", icon : "mbri-responsive", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Easy to customize", icon : "mbri-download", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
        ],

    }
    render() {
        return (
            <React.Fragment>
        <section id="service" className="section">
            <Container>
            <SectionTitle title1="Par" title2="mums" />
<p className={'about-us-text'}>
    Loose Fill prioritāte ir drošība. Drošība visos aspektos - ne tikai attiecībā uz pārvadājamām precēm, bet arī uz vidi un dabu. Tā kā mainās sabiedrības paradumi un ierasto iepirkšanos fiziskajos veikalos aizstāj e-veikali, mēs meklējam veidus, kā veikt preču piegādi, lai tā būtu pēc iespējas videi  draudzīgāka.  Saviem klientiem piedāvājam iespēju aizstāt ierastās plastmasas granulas ar bioloģiski noārdāmām un veikt vismaz nelielu ieguldījumu ikdienā radīto atkritumu apjoma samazināšanā. Mūsu mērķis ir padarīt bioloģiski noārdāmās iepakojuma granulas ne tikai par drošu, bet arī modernu iepakojuma risinājumu uzņēmumiem un mājsaimniecībām. Rūpēsimies par vidi kopā, jau tagad!
</p>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default Services;
